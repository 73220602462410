<template>
  <div class="page-content">
    <div class="container">
      <manual-link>{{ t('Keepaステータス') }}</manual-link>

      <div class="d-flex flex-wrap">
        <label class="d-flex flex-wrap mt-2">{{ t('拡張機能ステータス') }}
          <b-form-radio-group v-model="selectedExtStatus" class="ml-4">
            <b-form-radio value="0">{{ t('有効') }}</b-form-radio>
            <b-form-radio value="1">{{ t('無効') }}</b-form-radio>
          </b-form-radio-group>
        </label>
        <b-button class="bg-eresa mx-2" @click="confirmExtStatus()">{{ t('変更') }}</b-button>
        <b-spinner v-if="keepaStatusUpdating" variant="primary" small></b-spinner>
      </div>

      <div class="d-flex flex-wrap mt-2">
        <label class="mt-2 d-flex">{{ t('残トークン：') }}
          <div v-if="keepaStatus != void 0">{{ keepaStatus.current.tokensLeft }} ({{ formatDate(keepaStatus.current.createdAt) }})</div>
        </label>
        <b-button class="btn bg-eresa text-white mx-2" :title="t('最新の情報に更新')" @click="showKeepaStatus"><font-awesome-icon :icon="['fas', 'arrows-rotate']" /></b-button>
        <b-spinner v-if="loading" variant="primary" small></b-spinner>
      </div>
      <div class="mt-4">
        <label>{{ t('トークン履歴：') }}</label>
        <div v-if="keepaStatus != void 0" class="container keepa-history-table mt-2">
          <div class="d-flex justify-content-between font-weight-bold border-bottom">
            {{ t('日時') }}
            <div>
              <b-icon icon="sort-up-alt" class="sort mr-2" @click="sort('createdAt', false)"></b-icon>
              <b-icon icon="sort-down" class="sort mr-2" @click="sort('createdAt', true)"></b-icon>
            </div>
          </div>
          <div class="d-flex justify-content-between font-weight-bold border-bottom">
            {{ t('残トークン') }}
            <div>
              <b-icon icon="sort-up-alt" class="sort mr-2" @click="sort('tokensLeft', false)"></b-icon>
              <b-icon icon="sort-down" class="sort mr-2" @click="sort('tokensLeft', true)"></b-icon>
            </div>
          </div>
          <template v-for="(item, i) in keepaStatus.history">
            <div class="border-bottom" :key="`createdAt-${i}`">{{ formatDate(item.createdAt) }}</div>
            <div :class="item.tokensLeft <= KEEPA_TOKEN_THRESHOLD ? 'text-red border-bottom text-right' : 'border-bottom text-right'" :key="`tokensLeft-${i}`">{{ item.tokensLeft }}</div>
          </template>
        </div>
      </div>
    </div>

    <b-modal ref="updateConfirm" @ok="updateExtStatus" :title="t('変更')" :ok-title="t('変更')" :cancel-title="t('キャンセル')">
      {{ t('変更します。よろしいですか？') }}
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import ManualLink from '@/components/ManualLink.vue';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import Utils from '@/mixins/utils';
import AuthUtil from '@/mixins/authutil';

const KEEPA_TOKEN_THRESHOLD = 5000;

export default {
  name: 'ExtStatus',
  components: {
    ManualLink,
  },
  mixins: [Utils, AuthUtil],
  data() {
    return {
      KEEPA_TOKEN_THRESHOLD,
      selectedExtStatus: '0',
      keepaStatus: null,
      keepaStatusUpdating: false,
      loading: false,
    }
  },
  async mounted() {
    const r = await API.graphql(graphqlOperation(mutations.adminOperation, { operation: 'getEresaExpandedStatus' }));
    this.selectedExtStatus = r.data.adminOperation == 'true' ? '0' : '1';
    await this.showKeepaStatus();
    await this.validateAdmin();
  },
  methods: {
    confirmExtStatus() {
      this.$refs.updateConfirm.show();
    },
    async updateExtStatus() {
      this.keepaStatusUpdating = true;
      try {
        await API.graphql(graphqlOperation(mutations.adminOperation, {
          operation: 'updateEresaExpandedStatus',
          args: JSON.stringify({
            enable: this.selectedExtStatus == '0'
          })
        }));
      }
      finally {
        this.keepaStatusUpdating = false;
      }
    },
    async showKeepaStatus() {
      this.loading = true;
      try {
        const r = await API.graphql(graphqlOperation(mutations.adminOperation, { operation: 'getKeepaTokenStatus' }));
        this.keepaStatus = JSON.parse(r.data.adminOperation);
        this.sort('createdAt', true);
      }
      finally {
        this.loading = false;
      }
    },
    formatDate(date) {
      return moment(date).format('YYYY/MM/DD HH:mm');
    },
    sort(colname, desc) {
      if (desc == void 0) {
        return;
      }
      this.keepaStatus.history.sort((a, b) => {
        if (a[colname] === b[colname]) {
          return 0;
        }
        else {
          if (desc) {
            return a[colname] < b[colname] ? 1 : -1;
          }
          else {
            return a[colname] < b[colname] ? -1 : 1;
          }
        }
      });
    },
  },
}
</script>

<style scoped>
.keepa-history-table {
  display:grid;
  grid-template-columns: 10em 8.5em;
}

.sort {
  cursor: pointer;
}
</style>
